<template>
  <b-row>
    <b-col cols="4" class="animate__animated animate__fadeIn" v-for="e in experiences" :key="e._id">
        <b-card class="experience-card me-card">
          <router-link :to="`/experiences/${e.slug || generateSlug(e.title+' '+e.channel)}/${e._id}`">
            <v-carousel  touchless cycle hide-delimiters interval="4000" :show-arrows="false" height="350">
              <v-carousel-item
                v-for="(item,i) in e.mappedPhotos"
                :key="i"
                :alt="generateSlug(e.title+' localites community '+e.channel)"
                :src="item.img"
                :lazy-src="lazy"
              ></v-carousel-item>
            </v-carousel>
          </router-link>

          <div class="badge-online">{{ e.pricing.isAvailableFree ? 'Free' : (e.pricing.currency.symbol || e.pricing.currency.code)+(!e.pricing.currency.symbol ? ' ' : '')+e.pricing.amount }}</div>

          <div class="edit-delete-i" v-if="mode == 'edit'">
            <router-link :to="`/new-experience/modify/${e._id}`">
              <div class="badge-ed badge-edit">
                <i class="fa fa-pencil"></i>
              </div>
            </router-link>
            <div class="badge-ed badge-delete" @click="$parent.deleteItem(e)">
              <i class="fa fa-trash"></i>
            </div>
          </div>

          <div style="padding: 1.25rem;">
            <span v-if="mode == 'edit'">
              <small class="sc" v-if="e.status == 'active'">Active</small>
              <small class="dc" v-if="e.status == 'inactive'">Inactive</small>
              <small class="wc" v-if="e.status == 'indraft'">In draft</small>
              <span class="pc pl-1 pr-1">&#8226;</span>
              <small>{{e.totalBookings}} booking{{!e.totalBookings || e.totalBookings > 1 ? 's' : ''}}</small>
              <span class="pc pl-1 pr-1">&#8226;</span>
              <small>{{e.totalReviews}} review{{!e.totalReviews || e.totalReviews > 1 ? 's' : ''}}</small>
            </span>
            <span v-if="mode == 'booked'">
              <small>{{e.duration}}</small>
              <span class="pc pl-1 pr-1">&#8226;</span>
              <small>{{e.startsFrom}}</small>
            </span>

            <span v-if="mode == 'saved'">
              <small>{{e.duration}}</small>
              <span class="pc pl-1 pr-1">&#8226;</span>
              <small>{{e.totalReviews}} review{{!e.totalReviews || e.totalReviews > 1 ? 's' : ''}}</small>
            </span>
            
            <span class="pc pl-1 pr-1">&#8226;</span>
            <small>{{e.channel}}</small>

            <div class="ctitle">{{ e.title.length > 25 ? e.title.substring(0, 25-3) + '...' : e.title }}</div>
            <b-card-text>
              {{ e.duration }}, {{e.categories[0]}}
            </b-card-text>
          </div>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
  import OpenService from '../../services/open';

  export default {
    name: 'MExpSection',

    methods: {
      generateSlug(string) {
        return OpenService.generateSlug(string);
      }
    },

    props: [
      'experiences',
      'mode'
    ],

    data() {
      return {
        lazy: require("@/assets/imgs/placeholder.png")
      }
    }
  }
</script>