import Loading from '../../components/loading/loading.vue';
import OpenService from '../../services/open';
import ExperienceService from '../../services/experience';
import MExpSection from '../../components/m-exp-section/m-exp-section.vue';

export default {
  name: 'ManageExperiences',

  methods: {

    generateSlug(string) {
      return OpenService.generateSlug(string);
    },

    setFilteredExperiences() {
      this.hostingExperiences = this.experiences.hosting;
      this.experiencingExperiences = this.experiences.experiencing;

      this.hostingExperiences.forEach(x => {
        return ExperienceService.mapExperience(x);
      });

      this.experiencingExperiences.forEach(x => {
        return ExperienceService.mapExperience(x);
      });

      this.activeExperiences = this.hostingExperiences.filter(x => x.status === 'active');
      this.inactiveExperiences = this.hostingExperiences.filter(x => x.status === 'indraft' || x.status === 'inactive');

      this.bookedExperiences = this.experiencingExperiences.filter(x => x.type === 'booked');
      this.savedExperiences = this.experiencingExperiences.filter(x => x.type === 'saved');
    },

    fetchExperiences() {
      this.loading = true;
      const uuid = this.$store.state.uuid;
      ExperienceService
        .getExperiencesById(uuid)
        .then(r => {
          this.experiences = r.data;
          this.setFilteredExperiences();
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    deleteItem(exp) {
      this.beingRemoved = exp;
      this.confirmModal = true;
    },

    changeStatus(status) {
      this.saving = true;
      const expId = this.beingRemoved._id;
      ExperienceService
        .changeStatus(expId, status, true)
        .then(() => {
          this.hostingExperiences.find(x => x._id === expId).status = status;
          this.setFilteredExperiences();
          this.confirmModal = this.saving = false;
        })
        .catch(e => {
          console.log(e);
          this.confirmModal = this.saving = false;
        })
    }
  },

  components: {
    Loading,
    MExpSection
  },

  mounted() {
    this.fetchExperiences();
  },

  data() {
    return {
      dType: 'experiencing',
      loading: false,
      confirmModal: false,
      saving: false,
      beingRemoved: {},

      lazy: require("@/assets/imgs/placeholder.png"),
      experiences: {},

      hostingExperiences: [],
      experiencingExperiences: [],

      activeExperiences: [],
      inactiveExperiences: [],

      bookedExperiences: [],
      savedExperiences: []

    }
  }
}