<template>
  <b-row>
    <b-col cols="12" class="text-center mt-10">
      <v-progress-linear
        color="primary"
        indeterminate
        :rounded="!noBorderRadius"
        height="6"
      ></v-progress-linear>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    props: [
      'noBorderRadius'
    ]
  }
</script>